<template>
  <header
    style="
      background: #fff;
      font-size: 16px;
      line-height: 48px;
      padding: 0px 15px;
      display: flex;
      justify-content: space-between;
    "
  >
    <p style="font-size: 18px; font-weight: 600">Performance Stats</p>
    <!-- 从‘客户管理’跳转过来的不显示‘选择子账号’选择框， -->
    <div class="select_box" v-if="!store.state.userInfo.role">
      <a-select
        style="width: 250px"
        v-model:value="data.userId"
        @change="sub_accountChange"
      >
        <a-select-option :value="0">All</a-select-option>
        <a-select-option
          v-for="account in data.account_list"
          :value="account.id"
          :key="account.id"
        >
          {{ `${account.firstName}${account.lastName}` }}</a-select-option
        >
      </a-select>
    </div>
  </header>
  <div class="box">
    <a-row :gutter="[12, 12]" style="padding: 8px 0">
      <a-col
        :span="8"
        v-for="(item, index) in data.categoricalData"
        :key="index"
      >
        <div class="card">
          <div class="content">
            <div>{{ item.num }}</div>
            <div>{{ item.title }}</div>
          </div>
          <div class="img_box">
            <img :src="item.imgUrl" />
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 统计表 -->

    <a-row :gutter="[8, 8]">
      <a-col :span="12">
        <a-card style="border-radius: 8px">
          <template #title>
            <div class="card-title">
              <p>Task Stats [Exhibition Sign-up, Registration Stats]</p>
              <a-range-picker :placeholder="['Start Date','End Date']" @change="handler_performance_date" />
            </div>
          </template>
          <for-performance-management
            :date="data.performanceDate"
            :userId="data.userId"
          />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card style="border-radius: 8px; height: 100%">
          <template #title>
            <div class="card-title">
              <p>Call Stats</p>
              <a-date-picker placeholder="Select Date" @change="handler_callStatistics_date" />
            </div>
          </template>
          <call-statistics_chart
            :date="data.callStatisticsDate"
            :userId="data.userId"
          />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card style="border-radius: 8px; height: 100%">
          <template #title>
            <div class="card-title">
              <p>Inquiries, RFQ Stats</p>
              <a-range-picker :placeholder="['Start Date','End Date']" @change="handler_rfq_date" />
            </div>
          </template>
          <inquiry_charts
            :date="data.rfqStatisticsDate"
            :userId="data.userId"
          />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card style="border-radius: 8px; height: 100%">
          <template #title>
            <div class="card-title">
              <p>Video Stats</p>
              <a-date-picker placeholder="Select Date" @change="handler_video_date" />
            </div>
          </template>
          <video_chart :date="data.videoStatistics" :userId="data.userId" />
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onBeforeMount,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import forPerformanceManagement from '@/components/for_performanceManagement'
import CallStatistics_chart from '@/components/CallStatistics_chart'
import inquiry_charts from '@/components/inquiry_charts'
import video_chart from '@/components/video_chart'
import { myAchievement, SubAccountAndMine } from '@/api/performance'
import handler_date from '@/utils/handler_date'
export default defineComponent({
  name: 'performanceManagement',
  components: {
    forPerformanceManagement,
    CallStatistics_chart,
    inquiry_charts,
    video_chart,
  },
  setup(props) {
    const route = useRouter()
    const store = useStore()
    const data = reactive({
      userId: store.state.userInfo.id,
      account_list: [],
      categoricalData: [
        {
          id: 1,
          title: 'Total Number of  Registrations',
          imgUrl: require('@/images/register.png'),
          num: 0,
        },
        {
          id: 2,
          title: 'Total Number of Exhibitions',
          imgUrl: require('@/images/SignupExhibition.png'),
          num: 0,
        },
        {
          id: 3,
          title: 'Total Number of Inquiry',
          imgUrl: require('@/images/inquiry.png'),
          num: 0,
        },
        {
          id: 4,
          title: 'Total Number of Valid Video',
          imgUrl: require('@/images/Video_appointment.png'),
          num: 0,
        },
        {
          id: 5,
          title: 'Total Video Minutes',
          imgUrl: require('@/images/video_number.png'),
          num: 0,
        },
        {
          id: 6,
          title: 'Total Number of RFQs',
          imgUrl: require('@/images/RFQ.png'),
          num: 0,
        },
        {
          id: 8,
          title: 'Total Number of Invitations to Offline Exhibition',
          imgUrl: require('@/images/invite.png'),
          num: 0,
        },
        {
          id: 7,
          title: 'Total Number of Calls',
          imgUrl: require('@/images/call_up.png'),
          num: 0,
        },
        {
          id: 9,
          title: 'Total Number of Unavailable',
          imgUrl: require('@/images/answer_the_phone.png'),
          num: 0,
        },
      ],
      performanceDate: handler_date(),
      callStatisticsDate: handler_date(),
      rfqStatisticsDate: handler_date(),
      videoStatistics: handler_date(),
    })
    // if (route.currentRoute.value.query.userId) {
    //   data.userId = route.currentRoute.value.query.userId
    // }
    //  处理任务统计模块的时间选择
    function handler_performance_date(v, m) {
      data.performanceDate.startTime = m[0]
      data.performanceDate.endTime = m[1]
    }
    //  处理询盘、rfq模块的时间选择
    function handler_rfq_date(v, m) {
      data.rfqStatisticsDate.startTime = m[0]
      data.rfqStatisticsDate.endTime = m[1]
    }
    // 处理通话统计模块的时间选择
    function handler_callStatistics_date(v, m) {
      if (m) {
        data.callStatisticsDate.startTime = handler_date(5, m).startTime
        data.callStatisticsDate.endTime = m
      } else {
        data.callStatisticsDate.startTime = handler_date(5, m).startTime
        data.callStatisticsDate.endTime = handler_date(5, m).endTime
      }
    }
    // 处理有效视频模块的时间选择
    function handler_video_date(v, m) {
      if (m) {
        data.videoStatistics.startTime = handler_date(5, m).startTime
        data.videoStatistics.endTime = m
      } else {
        data.videoStatistics.startTime = handler_date(5, m).startTime
        data.videoStatistics.endTime = handler_date(5, m).endTime
      }
    }
    // 切换账号 重新获取统计数据
    function sub_accountChange(v) {
      getMyAchievement(v)
    }
    // 查询 当前账号及其子账号
    function getSubAccountAndMine() {
      SubAccountAndMine().then((res) => {
        data.account_list = res.data
      })
    }
    getSubAccountAndMine()
    getMyAchievement(data.userId)
    // 获取 我的业绩 数据
    function getMyAchievement(id) {
      myAchievement({ userId: id }).then((res) => {
        const myAchievementInfo = {
          ...res.data.myAchievementVo[0],
          ...res.data.myCustomerProcessVo[0],
        }
        data.categoricalData.forEach((el) => {
          switch (el.id) {
            case 1:
              el.num = myAchievementInfo.registerCount || 0
              break
            case 2:
              el.num = myAchievementInfo.signUpExhibitionCount || 0
              break
            case 3:
              el.num = myAchievementInfo.inquiryCount || 0
              break
            case 4:
              el.num = myAchievementInfo.effectiveAppointmentCount || 0
              break
            case 5:
              el.num = myAchievementInfo.appointmentTime || 0
              break
            case 6:
              el.num = myAchievementInfo.rfqCount || 0
              break
            case 7:
              el.num = myAchievementInfo.talkCount || 0
              break
            case 8:
              el.num = myAchievementInfo.inviteExhibitionCount || 0
              break
            case 9:
              el.num = myAchievementInfo.missTalkCount || 0
              break
          }
        })
      })
    }
    return {
      sub_accountChange,
      handler_callStatistics_date,
      handler_performance_date,
      handler_rfq_date,
      handler_video_date,
      data,
      store,
      route,
    }
  },
})
</script>

<style lang="less" scoped>
.card {
  background: #fff;
  height: 108px;
  border-radius: 8px;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  > div {
    // width: 50%;
    display: flex;
  }
  .content {
    width: 65%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    div {
      text-align: center;
      height: 50%;
      display: flex;
      align-items: center;
    }
    :first-child {
      padding-top: 30px;
      font-size: 30px;
      font-weight: 600;
      color: #333333;
    }
    :last-child {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }
  .img_box {
    width: 35%;
    justify-content: center;
  }
}
.card:hover {
  box-shadow: 0px 0px 10px #ddd;
  cursor: pointer;
}
.card-title {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    color: #333333;
    font-weight: 600;
    font-size: 17px;
    margin: 0;
  }
}
header p {
  margin: 0;
}
.box {
  margin: 0 16px 16px 16px;
}
</style>
