<template>
  <div class="placeholder"></div>
  <div id="video_chart"></div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  onBeforeMount,
  onUnmounted,
} from 'vue'
import * as echarts from 'echarts'
import { effectiveVideoStatistics } from '@/api/performance'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: ['date', 'userId'],
  setup(props, context) {
    let router = useRouter()
    let myEcharts = null
    const option = reactive({
      legend: {
        show: true,
        right: 0,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        // formatter: (value) => {
        //   return `${value[0].data[0]}<br/>
        //   total video duration: ${value[0].data[1]}<br/>
        //   Effective video time: ${value[0].data[2]}min`
        // },
        // // formatter: '{b}<br/> {a}: {c0} {c1}',
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          rotate: 25,
        },
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
        },
        {
          type: 'value',
          min: 0,
          axisLabel: {
            formatter: '{value} min',
          },
        },
      ],
      series: [
        {
          name: 'Total Video Duration',
          data: [],
          type: 'bar',
        },
        {
          name: 'Effective Video Time',
          data: [],
          type: 'bar',
          yAxisIndex: 1,
        },
      ],
    })
    function getEffectiveVideoStatistics() {
      const submitForm = props.date
      if (router.currentRoute.value.query.userId) {
        submitForm.userId = router.currentRoute.value.query.userId
      } else {
        submitForm.userId = props.userId || 0
      }
      effectiveVideoStatistics(submitForm)
        .then((res) => {
          option.xAxis.data = []
          option.series[0].data = []
          option.series[1].data = []
          res.data.forEach((el) => {
            option.xAxis.data.push(el.date)
            option.series[0].data.push(el.appointmentTime)
            option.series[1].data.push(el.effectiveAppointmentCount)
          })
          if (myEcharts) {
            myEcharts.dispose()
          }
          myEcharts = echarts.init(document.getElementById('video_chart'))
          myEcharts.setOption(option)
        })
        .catch((err) => {
          console.log('失败')
        })
    }
    onMounted(() => {
      myEcharts = echarts.init(document.getElementById('video_chart'))
      option && myEcharts.setOption(option)
      window.addEventListener('resize', function () {
        myEcharts.resize()
      })
    })
    watch(
      () => props.date.endTime,
      (newValue, oldValue) => {
        getEffectiveVideoStatistics()
      },
      { immediate: true, deep: true }
    )
    watch(
      () => props.userId,
      (newValue, oldValue) => {
        getEffectiveVideoStatistics()
      },
      { deep: true, immediate: false }
    )
    onUnmounted(() => {
      window.removeEventListener('resize', function () {
        myEcharts.resize()
      })
    })
  },
})
</script>

<style lang="less" scoped>
#video_chart {
  min-height: 400px;
}
.placeholder {
  height: 42px;
}
</style>
